import React, { useState, useEffect } from 'react'

import './Property.scss'
import './PropertyOverview.scss'

import PropertyLayout from '../../components/Layout/PropertyLayout'
import PropertyBaseMenu from '../../components/PropertyBaseMenu/PropertyBaseMenu'
import Map from '../../components/Map/Map'

import Fold from '../../assets/images/fold.png'
import Mesa from '../../assets/svg/mesa.svg'
import { getCorrectLogoBrandMobile } from '../../utils/getCorrectLogoBrandMobile'
import { getCorrectLogoBrand } from '../../utils/getCorrectLogoBrand'
import { useDevice, DeviceState } from '../../utils/checkScreen'

const PropertyLocation = (data: any): JSX.Element => {
  const [device, setDevice] = useState({} as DeviceState)
  const pageData = data
  const { pageContext } = pageData
  const { property } = pageContext
  const { propertyLocation } = property.location

  const { brand } = property.details

  let logoBrand = getCorrectLogoBrand(brand)
  let logoBrandMobile = getCorrectLogoBrandMobile(brand)

  const mapData = {
    propertyLocation: {
      name: propertyLocation.name,
      place: propertyLocation.place,
      lat: propertyLocation.lat,
      long: propertyLocation.long
    },
    propertyMarkers: property.location.propertyMarkers ? property.location.propertyMarkers : []
  }

  useEffect(() => {
    setDevice(useDevice())
  }, [])

  const [tab, setTab] = useState('landmarks')

  const selectLandMark = (id: string): void => {
    const marker = document.getElementById(`md-${id}`) as HTMLElement
    const balloon = document.getElementById(`mb-${id}`) as HTMLElement

    if (marker.style.display === 'none') {
      balloon.click()
    } else {
      marker.click()
    }
  }

  const switchTab = (ntab: string): void => {
    const markerPoints: any = document.querySelectorAll('.map-marker-landmark')
    const markerDialogs: any = document.querySelectorAll('.marker-balloon')

    markerDialogs.forEach((marker: any): void => {
      marker.style.display = 'none'
    })

    markerPoints.forEach((marker: any): void => {
      marker.style.display = 'none'
    })

    const landPoints: any = document.querySelectorAll('.marker-landmark')
    const nearPoints: any = document.querySelectorAll('.marker-establishment')

    if (ntab === 'landmarks') {
      landPoints.forEach((marker: any) => {
        marker.style.display = 'block'
      })
    } else {
      nearPoints.forEach((marker: any) => {
        marker.style.display = 'none'
      })
    }

    setTab(ntab)
  }

  useEffect(() => {
    setTimeout(() => {
      switchTab(tab)
    }, 200)
  }, [])

  return (
    <>
      <PropertyLayout active={2} pageData={pageData}>
        <PropertyBaseMenu pageData={pageData} />
        <div className="property-page-content">
          <Map data={mapData} />
          <div className="poperty-overview-page" style={{ height: '0px' }}>
            <div
              className="property-overview-banner"
              id="project-overview-banner"
              style={{
                background: `none`
              }}
            >
              <div className="content-wrapper">
                <div className="brand-logo" style={{ position: 'relative', top: '-540px' }}>
                  <img src={logoBrand} alt="brand-logo" />
                </div>
                <div className="property-logo" id="map-property-logo">
                  <img
                    src={property.details.imageLogo && property.details.imageLogo}
                    alt="property logo"
                    style={{ position: 'relative', top: '-527px' }}
                  />
                </div>
                <div className="page-fold web-view-page-fold no-price">
                  <img src={Mesa} alt="page fold" className="page-shape" />
                  <img src={Fold} alt="edge fold" className="edge-fold" />

                  <div className="page-fold-content">
                    <span>
                      Location <small>---------</small>
                    </span>
                    {device.isDesktop || device.isTablet ? (
                      <h1 style={{ textTransform: 'uppercase' }}>{property.location.propertyLocation.place}</h1>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="property-overview-content">
            <div className="mobile-property-context">
              <div className="property-logo-mobile">
                <img src={logoBrandMobile} alt="brand-logo" className="x-brand-logo" />
                <img src={property.details.imageLogo} className="mobile-brand-logo" alt="property-brand-logo" />
              </div>
              {property.location.title && device.isMobile ? (
                <div className="type-title">
                  <h1>{property.location.title}</h1>
                </div>
              ) : null}
            </div>
            <div className="property-text">
              <p>{property.location.propertyLocation.description}</p>
            </div>

            <div className="property-location-tabs">
              <div className="property-tabs">
                <div
                  className={`tab ${tab === 'landmarks' ? 'active' : ''}`}
                  onClick={() => {
                    switchTab('landmarks')
                  }}
                >
                  <span>Landmarks</span>
                </div>
              </div>
              {tab === 'landmarks' && (
                <div className="property-tab-content-landmarks">
                  <div className="item-row">
                    {mapData.propertyMarkers
                      .filter((x: any) => x.type === 'landmark')
                      .map((marker: any) => {
                        return (
                          <div
                            key={marker.id}
                            className="item"
                            id={`lm-${marker.id}`}
                            onClick={() => {
                              selectLandMark(marker.id)
                            }}
                          >
                            <span>
                              {marker.name}
                              {marker.distance ? `- ${marker.distance}` : ''}
                            </span>
                          </div>
                        )
                      })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </PropertyLayout>
    </>
  )
}

export default PropertyLocation
