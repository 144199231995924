import AlpBrand from '../assets/images/brands/alp.png'
import AlveoBrand from '../assets/images/brands/Alveo.png'
import AvidaBrand from '../assets/images/brands/Avida.png'
import AmaiaBrand from '../assets/images/brands/Amaia.png'
import BellavitaBrand from '../assets/images/brands/BellaVita.png'

export const getCorrectLogoBrandMobile = (brand: string) => {
    switch (brand) {
        case 'alp':
            return AlpBrand
        case 'alveo':
            return AlveoBrand
        case 'avida':
            return AvidaBrand
        case 'amaia':
            return AmaiaBrand
        case 'bellavita':
            return BellavitaBrand
    }
}