import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import './Map.scss'
import land_mark from '../../assets/svg/point-green.svg'
import point from '../../assets/svg/triangle.svg'

const MapTheme = [
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f7f7f7'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 17
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 29
      },
      {
        weight: 0.2
      }
    ]
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 18
      }
    ]
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f5f5f5'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#dedede'
      },
      {
        lightness: 21
      }
    ]
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        visibility: 'on'
      },
      {
        color: '#ffffff'
      },
      {
        lightness: 16
      }
    ]
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        saturation: 36
      },
      {
        color: '#b7b7b7b7'
      },
      {
        lightness: 40
      }
    ]
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'transit',
    elementType: 'geometry',
    stylers: [
      {
        color: '#f2f2f2'
      },
      {
        lightness: 19
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 20
      }
    ]
  },
  {
    featureType: 'administrative',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#fefefe'
      },
      {
        lightness: 17
      },
      {
        weight: 1.2
      }
    ]
  }
]

const PropertyMarker = ({ text }: any) => <div id="map-marker"></div>

const showBalloon = (id: string) => {
  let balloon = document.getElementById(`mb-${id}`) as HTMLElement
  let marker = document.getElementById(`md-${id}`) as HTMLElement
  let selector = document.getElementById(`lm-${id}`) as HTMLElement

  marker.style.display = 'none'
  balloon.style.display = 'flex'
  selector.classList.add('active')
}

const showMarker = (id: string) => {
  let balloon = document.getElementById(`mb-${id}`) as HTMLElement
  let marker = document.getElementById(`md-${id}`) as HTMLElement
  let selector = document.getElementById(`lm-${id}`) as HTMLElement

  balloon.style.display = 'none'
  marker.style.display = 'block'
  selector.classList.remove('active')
}

const MapMarker = (props: any) => {
  const marker = props.data
  return (
    <div>
      <div
        className={`map-marker-landmark fade-in marker-${marker.type} ${marker.type === 'establishment' ? `est-${marker.category}` : ''}`}
        id={`md-${marker.id}`}
        onClick={() => {
          showBalloon(marker.id)
        }}
      >
        {marker.type === 'landmark' && <div className="dot" />}
        {marker.type === 'establishment' && <i className={`${marker.icon} marker-icon`} />}
        <img src={land_mark} className="marker-landmark" />
      </div>
      <div
        className={`marker-balloon fade-in-bottom ${marker.type === 'establishment' ? `estb-${marker.category}` : ''}`}
        id={`mb-${marker.id}`}
        onClick={() => {
          showMarker(marker.id)
        }}
      >
        <div className="balloon-card">
          <i className="la la-times balloon-close" />
          <strong>{marker.name}</strong>
          <a href={marker.url} target="_blank" rel="noreferrer noopener">
            <i className="la la-map-marker" /> View on Google Maps
          </a>
        </div>
        <img src={point} />
      </div>
    </div>
  )
}

const Map = (props: any) => {
  const data = props.data

  if (!data.propertyLocation.lat || !data.propertyLocation.long) {
    return <div style={{ height: '528px', width: '100%' }} className="map-container no-map"></div>
  }
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '528px', width: '100%' }} className="map-container">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyD5kFZMwUIUDZ25nTtLx0_0G3x1d2GMiCY' }}
        defaultCenter={{
          lat: data.propertyLocation.lat,
          lng: data.propertyLocation.long
        }}
        defaultZoom={17}
        options={{
          scrollwheel: true,
          styles: MapTheme
        }}
      >
        <PropertyMarker lat={data.propertyLocation.lat} lng={data.propertyLocation.long} text="Property" />

        {data.propertyMarkers.map((marker: any) => {
          return <MapMarker lat={marker.lat} lng={marker.long} data={marker} />
        })}
      </GoogleMapReact>
    </div>
  )
}

export default Map
